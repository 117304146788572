<template>
  <div class="Admin">
    <v-container class="mt-0">
      <v-toolbar color="#16283F" dark flat>
        <v-tabs align="left" fixed-tabs>
          <v-tab left small tile text @click="setstatus(`eventsCalendar`)">Events Calendar </v-tab>

          <v-tab small tile text @click="setstatus(`worklist`)">Worklist </v-tab>
        </v-tabs>
      </v-toolbar>
      <event v-if="eventsCalendar" :eventlist="todos"> </event>

      <div v-if="worklist">
        <v-card flex class="px-2">
          <v-layout row wrap class="subheading darken3">
            <v-flex xs12 sm12 md12 lg12 px-3> YOU HAVE {{ numberOfNewEmail }} NEW EMAIL REQUEST </v-flex>
          </v-layout>
        </v-card>
        <v-card flex class="px-2">
          <v-layout row wrap class="subheading darken3">
            <v-flex xs4 sm4 md3 lg2 pl-3>
              <div class="grey--text">EMP ID</div>
            </v-flex>
            <v-flex xs4 sm4 md3 lg3 pl-3>
              <div class="grey--text">EMP NAME</div>
            </v-flex>
          </v-layout>
        </v-card>
        <v-card justify="center" class="py-2" v-for="newEmail in newEmails" :key="newEmail.uid">
          <v-layout row wrap>
            <v-flex xs4 sm4 md3 lg2 pl-3>
              {{ newEmail.empid }}
            </v-flex>
            <v-flex xs4 sm4 md3 lg3 pl-3>
              {{ newEmail.name }}
            </v-flex>

            <v-flex xs1 sm1 md1 lg1 pl-1>
              <v-btn v-if="editflag" tile text color="secondary" @click="viewemprecord(newEmail)"> VIEW </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

import { empRef, activityRef, assetRef } from "../fb.js"
import event from "../components/calendar/events"

export default {
  name: "SysAdmin",
  // props:['roles'],
  components: {
    event,
  },

  data: () => ({
    adminmenus: [
      { name: "Activity Lists", link: "activity" },
      { name: "Asset Lists", link: "asset" },
      { name: "Vendor Lists", link: "vendor" },
    ],

    role: "admin,all",
    numberOfNewEmail: 0,
    newEmails: [],
    newEmailFlag: false,
    newinsurancedates: [],
    newinsuranceflag: false,
    numberofnewinsurance: 0,
    domain: "",
    editflag: false,

    todos: [],
    roles: "",
    eventsCalendar: true,
    worklist: false,
  }),

  async created() {
    this.roles = await this.$store.state.userProfile
    console.log("Admin crated", this.roles)
    if (this.roles) {
      if (this.roles.role.sysadmin || this.roles.role.readuser) {
        if (this.roles.role.sysadmin) {
          this.editflag = true
        }
        this.domain = this.roles.domain
        this.emailcreationlist()
        this.queryevents()
      } else {
        alert("Not authorized to access this page , kindly contact support")

        this.$router.replace({ name: this.roles.menufrom })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },

  methods: {
    setstatus(taboption) {
      this.eventsCalendar = false
      this.worklist = false
      if (taboption == "eventsCalendar") {
        this.eventsCalendar = true
      } else if (taboption == "worklist") this.worklist = true

      console.log("inside setstatus", taboption, this.eventsCalendar, this.worklist)
    },
    async emailcreationlist() {
      var queryresult = ""
      var queryval = ""
      console.log("inside queryresult befoe query", this.domain, queryval)
      queryresult = await empRef.where("domain", "==", this.domain).where("email", "==", null).get()
      console.log("queryresult for null email", queryresult.docs.length)
      if (queryresult.docs.length > 0) {
        console.log("inside queryresult found")
        this.numberOfNewEmail = queryresult.docs.length
        this.newEmailFlag = true
        this.worklist = true
        this.eventsCalendar = false
        var i
        for (i = 0; i < this.numberOfNewEmail; ++i) {
          this.newEmails.push({
            empid: queryresult.docs[i].data().empid,
            email: queryresult.docs[i].data().personalDetails.email,
            name: queryresult.docs[i].data().name,
            mgrName: queryresult.docs[i].data().mgrname,
            uid: queryresult.docs[i].id,
          })
          console.log(this.newEmails[i])
        }
      }
    },
    viewemprecord(newEmail) {
      alert("inside viewempreocord")
      var tmpempvalues
      tmpempvalues = {
        EmpID: newEmail.empid,
        email: newEmail.email,
        status: "active",
        role: "sysadmin",
        name: newEmail.name,
        mgrName: newEmail.mgrName,
        domain: this.domain,
      }
      console.log("tmpempvalues", tmpempvalues)
      this.$router.push({ name: "employee", params: { roles: tmpempvalues } })
    },
    async getassetinsurance() {
      var queryresult = ""
      var i = 0
      console.log("inside queryresult befoe query")
      queryresult = await assetRef.where("domain", "==", this.domain).get()
      var d = new Date()
      this.add_weeks(d, 2)

      console.log("queryresult for venzo asset list", queryresult.docs.length)
      if (queryresult.docs.length > 0) {
        for (i = 0; i < queryresult.docs.length; i++) {
          if (queryresult.docs[i].data().insuranceExpiryDate) {
            var tmpdate1 = new Date(queryresult.docs[i].data().insuranceExpiryDate)
            var tmpdata = queryresult.docs[i].data().insuranceExpiryDate
            // this.add_weeks(tmpdate1,2)
            // var strtmpdate1=year+"/"+(tmpdate1.getMonth()+1)+"/"+tmpdate1.getDate();
            if (d > tmpdate1) {
              this.newinsuranceflag = true
              this.numberofnewinsurance++
              this.newinsurancedates.push({
                insuranceExpiryDate: tmpdata,
                id: queryresult.docs[i].data().id,
              })
            } //end of  if(dateStr > tmpdate1)
          }
        } //end of for
      } //end of if(queryresult.docs.length>0)
    },
    add_weeks(dt, n) {
      return new Date(dt.setDate(dt.getDate() + n * 7))
    },

    async queryevents() {
      var i = 0
      var queryresult = await activityRef.where("domain", "==", this.domain).get()
      for (i = 0; i < queryresult.docs.length; ++i) {
        await this.todospush(
          "Activity",
          queryresult.docs[i].data().nextService,
          "green",
          queryresult.docs[i].data().name,
          queryresult.docs[i].data().comments
        )
      }
      queryresult = await assetRef.where("domain", "==", this.domain).get()
      for (i = 0; i < queryresult.docs.length; ++i) {
        await this.todospush(
          "Insurance",
          queryresult.docs[i].data().insuranceExpiryDate,
          "blue",
          queryresult.docs[i].data().id,
          queryresult.docs[i].data().serialNumber
        )
      }
    },

    todospush(eventname, eventdate, eventcolor, fname, fcomments) {
      var tmpdetails
      if (eventdate) {
        var tmpdate = new Date(eventdate)
        var currentdate = new Date()
        if (currentdate > tmpdate) {
          eventcolor = "red"
        }
        //  console.log("inside todos",eventname,tmpdate,eventcolor,fname,fcomments)
        if (eventname == "Activity") {
          tmpdetails = fname
        } else {
          tmpdetails = "Item ID:" + fname + ",Serial Number:" + fcomments
        }

        this.todos.push({
          name: tmpdetails, //eventname
          start: tmpdate,
          end: tmpdate,
          color: eventcolor,
          id: fname,
          details: eventname, //tmpdetails
        })
      }
    }, // end of todospush
  }, // end of methods
}
</script>

<style scoped></style>
